import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import * as yup from 'yup';
import { authenticationService } from '../services/authentication.service';
import auth from '../auth';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

const validationSchema = yup.object({
    username: yup
        .string('Enter your username')
        .required('Username is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error : false,
            errormsg : ''
        }
    }

    render() {
        const { classes } = this.props;

        const customChange = (e, setFieldValue) => {
            setFieldValue(e.target.id, e.target.value);
        };

        return (
            <div className={classes.root}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Formik
                            className={classes.form}
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    authenticationService.login(values.username, values.password)
                                        .then(
                                            user => {
                                                auth.login(() => {
                                                    this.props.history.push("/prodscreen/dashboard/");
                                                  });
                                            },
                                            error => {
                                                setSubmitting(false);
                                                this.setState({error : true, errormsg : error});
                                                return error;
                                            }
                                        );
                                }, 500);
                            }}
                        >
                            {({ submitForm, isSubmitting, setFieldValue, touched, errors }) => (

                                <Form>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label="username"
                                        name="username"
                                        autoFocus
                                        type="text"
                                        onChange={e => customChange(e, setFieldValue)}
                                    />
                                    {errors.username && touched.username ? (
                                        <div>{errors.username}</div>
                                    ) : null}
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="password"
                                        type="password"
                                        id="password"
                                        onChange={e => customChange(e, setFieldValue)}
                                    />
                                    {errors.password && touched.password ? (
                                        <div>{errors.password}</div>
                                    ) : null}
                                    {this.state.error ? (
                                        <div>{this.state.errormsg}</div>
                                    ) : null }
                                    {isSubmitting && <LinearProgress />}
                                    <br />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Sign In
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </div>
        );
    }
} export default withStyles(styles)(Home);
