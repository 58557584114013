import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";

import Home from "./components/Home"
import Dashboard from "./components/Dashboard"

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/prodscreen/" component={withRouter(Home)} />
          <ProtectedRoute exact path="/prodscreen/dashboard" component={withRouter(Dashboard)} />
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </Router>
    );
  }
} export default App;
