import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import auth from '../auth';
import validator from 'validator';


const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
});


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errormsg: '',
            date: null,
            success: false,
            successmsg: 'Upload was successful!',
            text: ''
            
        }
        this.submitScreen = this.submitScreen.bind(this);
    }

    logout() {
        auth.logout();
        this.props.history.push('/prodscreen/')
    }

    componentDidMount() {
        fetch("https://lab.schindler-education.ch/prodscreen/api/screen")
            .then(response => response.json())
            .then(data => this.setState({ data: data[0], text: data[0].value.text, date: new Date(data[0].value.date).toISOString().substr(0, 10) }));
    }

    submitScreen(values) {
        if (auth.authenticated && !this.state.error) {
            this.setState({ error: false })
            fetch("https://lab.schindler-education.ch/prodscreen/api/screen/push", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).access_token
                },
                body: JSON.stringify(values)
            })
                .then(res => this.setState({ success: res.ok }))
        } else {
            this.setState({ error: true, errormsg: 'User Authentification is ' + auth.authenticated + '. Could not perform Update. Text or/and Date is/are invalid.' });
        }
    }

    render() {
        const { classes } = this.props;

        const customChange = (e, setFieldValue) => {
            setFieldValue(e.target.id, e.target.value);
            if (validator.isDate(e.target.value)) {
                if (!validator.isAfter(e.target.value)) {
                    this.setState({ date: e.target.value, error: false, success: false });
                } else {
                    this.setState({ date: e.target.value, error: true, errormsg: e.target.value + ' is not a valid date.', success: false })
                }
            } else if ( !validator.isDate(e.target.value) ) {
                if (e.target.value.length > 50 ) {
                    this.setState({ text: e.target.value, error: true, errormsg: 'Input is limited to 50 characters.', success: false });
                } else {
                    this.setState({ text: e.target.value, error: false, success: false });
                }
            }
        };

        return (
            <div className={classes.root}>
                <Box display="flex" justifyContent="flex-end" alignItems='center'>
                    <Typography component="h1" variant="h6">
                        Logout
                    </Typography>
                    <IconButton aria-label="logout" className={classes.logout} onClick={() => this.logout()} >
                        <ExitToAppIcon />
                    </IconButton>
                </Box>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOpenIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Update
                        </Typography>
                        <Formik
                            enableReinitialize="true"
                            className={classes.form}
                            initialValues={{
                                text: this.state.text,
                                date: this.state.date,
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    if (validator.isDate(values.date) && values.text) {
                                        this.submitScreen({
                                            text: values.text,
                                            date: values.date,
                                            id: '0',
                                        })
                                    } else {
                                        this.setState({ error: true, errormsg: 'Date or/and Text is/are invalid.' });
                                    }
                                }, 500);
                            }}
                        >
                            {({ submitForm, isSubmitting, setFieldValue, touched, errors }) => (

                                <Form>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="text"
                                        label="text"
                                        name="text"
                                        autoFocus
                                        type="text"
                                        value={this.state.text}
                                        onChange={e => customChange(e, setFieldValue)}
                                    />
                                    {errors.username && touched.username ? (
                                        <div>{errors.username}</div>
                                    ) : null}
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="date"
                                        type="date"
                                        id="date"
                                        value={this.state.date}
                                        onChange={e => customChange(e, setFieldValue)}
                                    />
                                    {errors.date && touched.date ? (
                                        <div>{errors.date}</div>
                                    ) : null}
                                    {this.state.error ? (
                                        <div>{this.state.errormsg}</div>
                                    ) : null}
                                    {this.state.success ? (
                                        <div>{this.state.successmsg}</div>
                                    ) : null}
                                    {isSubmitting && <LinearProgress />}
                                    <br />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Update
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </div>
        );
    }
} export default withStyles(styles)(Dashboard);