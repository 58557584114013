import jwt_decode from "jwt-decode";

class Auth {
    constructor() {
        this.authenticated = false;

        this.logout = this.logout.bind(this);
    }


    login(cb) {
        // var isExpired = false;
        const token = localStorage.getItem('currentUser');
        var decoded = jwt_decode(token);
        var dateNow = new Date();

        if (decoded.exp < dateNow.getTime())
            // isExpired = true;
        this.authenticated = true;
        cb();
    }

    logout() {
        this.authenticated = false;
        localStorage.clear();
    }

    isAuthenticated() {
        return this.authenticated;
    }
}
export default new Auth();
